import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FasciaPaginaGenerica from "../components/fascia-pagina-generica";
import withPreview from "../utility/with-preview";

const IndexPage = ({ data, location }) => {
  const { title, seo, fasce, navMenu, topMenu, theme } = data.page;
  //useHandleRecaptchaBadge(fasce);
  return (
    <Layout
      location={location}
      navMenu={navMenu}
      topMenu={topMenu?.value}
      theme={theme?.value?.[0]}
    >
      <Seo
        title={seo?.content?.htmlTitle?.value || title?.value}
        description={seo?.content?.metaDescription?.value}
        ogImage={seo?.content?.ogImage?.node}
        path="/"
      />
      {fasce?.map((fascia, i) => (
        <FasciaPaginaGenerica
          fascia={fascia.value}
          key={i}
          location={location}
          title={seo?.content?.htmlTitle?.value || title?.value}
        />
      ))}
    </Layout>
  );
};
export default withPreview(IndexPage, {
  subField: "page",
  fixed: true,
});
export const query = graphql`
  query {
    page: liferayJskLayoutPaginaGenerica(friendlyUrl: { value: { eq: "/" } }) {
      liferayFields {
        siteId
        articleId
      }
      seo {
        content {
          htmlTitle {
            value
          }
          metaDescription {
            value
          }
          ogImage {
            node {
              resize(width: 1200) {
                src
                width
                height
              }
            }
          }
          metaRobots {
            value
          }
        }
      }
      title {
        value
      }
      fasce {
        value {
          ...FasciaPaginaGenericaFragment
        }
      }
      navMenu {
        ...MenuNavigazioneFragment
      }
      topMenu {
        value {
          ...JskTopMenuFragment
        }
      }
      theme {
        value
      }
    }
  }
`;
